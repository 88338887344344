@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Login {
  min-height: 100vh;
  padding: 10vh 14vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: var.$colorLoginBackground;

  & * {
    font-family: 'Inter', sans-serif !important;
  }
  &-content {
    min-width: 480px;
    display: flex;
    flex-flow: column nowrap;
    gap: func.rem(40px);
  }
  &-box {
    box-shadow: 0px 16px 40px 0 rgba(#64748b, 0.15);
    background-color: var.$colorWhite;
    padding: func.rem(40px);
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: var.$defaultBorderRadius;
  }
  &-logo {
    height: func.rem(40px);
    width: auto;
    margin-bottom: func.rem(40px);
  }
  &-form {
    width: 100%;
    max-width: func.rem(360px);
    display: flex;
    flex-flow: column nowrap;
    gap: func.rem(24px);
  }
  &-version {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: func.rem(4px);
    margin-top: func.rem(24px);

    p {
      font-size: func.rem(12px);
      color: var.$colorLoginGray;
      font-weight: var.$fontNormal;
    }
    &--changelog {
      cursor: pointer;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  &-otherApp {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: func.rem(8px);
    cursor: pointer;

    img {
      height: func.rem(32px);
      width: auto;
    }
    &--text {
      font-size: func.rem(12px);
      color: var.$colorLoginGray;
      font-weight: var.$fontNormal;
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      .Login-otherApp--text {
        text-decoration: none;
      }
    }
  }
  &-copyright {
    width: 100%;
    border-top: 1px solid var.$colorLoginGray2;
    padding-top: func.rem(16px);

    p {
      font-size: func.rem(12px);
      color: var.$colorLoginGray;
      font-weight: var.$fontNormal;
      text-align: center;

      a {
        font-weight: var.$fontNormal;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
